import React, {useState, useEffect} from "react";
import s from "./paginator.module.scss";
import ReactPaginate from 'react-paginate';
import { ReactComponent as PrevIcon } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as NextIcon } from "../../assets/icons/right-arrow.svg";
import DropdownController from "../dropdown/dropdown-controller";
import { SelectDropdown } from "commons/input-controls";

const Paginator = (props) => {
    const [currentPage, setCurrentPage] = useState(parseInt(props.page) >= 0 ? parseInt(props.page) : parseInt(props.currentPage));
    const [pageCount, setPageCount] = useState(Math.ceil(parseInt(props.page) >= 0 ? props.count/props.showedCount : props.itemsCount/props.showedItemsCount));
    const [selectedCount, setSelectedCount] = useState(props.showedCount ? props.showedCount : props.showedItemsCount)
    const items = [
        {name: 10, short_name: 10, title: 10},
        {name: 20, short_name: 20, title: 20},
        {name: 50, short_name: 50, title: 50},
        {name: 100, short_name: 100, title: 100}
    ];

    useEffect(() => {
        setCurrentPage(parseInt(props.page) >= 0 ? parseInt(props.page) : parseInt(props.currentPage));
        setSelectedCount(props.showedCount ? props.showedCount : props.showedItemsCount);
        setPageCount(Math.ceil(parseInt(props.page) >= 0 ? props.count/props.showedCount : props.itemsCount/props.showedItemsCount));
    }, [props]);

    const changePage = (event) => {
        if(props.callback){
            props.callback('changePage', event);
        }
        else
            props.setCurrentPage(!isNaN(event.selected) ? event.selected : event-1);
    }

    const changeLimit = (type, e) => {
        if(props.callback){
            props.callback('changeLimit', e)
        }
        else{
            props.setShowedItemsCount(e);
            props.setCurrentPage(0);
        }
    }

    const prevIconItem = <div><PrevIcon /></div>
    const nextIconItem = <div><NextIcon /></div>
    const breakLabel =<div className={s.breakLabel}><span>...</span></div>

    const getPages = (pageCount) => {
        let pages = []
        for(let i = 0; i <=pageCount; i++){
            pages[i] = i+1
        }
        return pages
    }

    return <div className={s.PaginatorBlock}>
        {props.deviceType !== "mobile" ? <ReactPaginate
            breakLabel={breakLabel}
            nextLabel={nextIconItem}
            onPageChange={changePage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={prevIconItem}
            renderOnZeroPageCount={null}
            activeClassName={s.selectedPage}
            disabledClassName={s.disableButton}
            forcePage={currentPage}
        />
        : <div>
            <SelectDropdown selected={currentPage+1} items={getPages(pageCount-1)} setSelected={changePage} height={300}/>
            </div>}
        <span className={s.showRowsCount}>{props.getValue('SHOW')}</span>
        <DropdownController 
                    items={items} 
                    callback={changeLimit} 
                    selected={selectedCount}
                    color={'var(----echoTextPrimary)'}
                    type={'number'}/>
    </div>
}

export default Paginator;