import AdminTopicsController from "./admin-topics-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getAllTopics } from "store/admin-topics";
//import { getAccounts, getTopicAccounts } from "store/superadmin";
import { getAllMarkers } from "store/admin-markers";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import {getAdminTableFromState} from "selectors/admin-topics-selector"
import { setItemsCount, setCurrentPage, setShowedItemsCount } from "store/paginator";
import { getMarkers, makeTopicVisibleToMarker, getSuperadminCompanies, getAccounts} from "store/superadmin"

let mapStateToProps = (state) => {
    return {
        adminTopics: state.AdminTopics.topics,
        tableHeader: state.AdminTopics.tableHeader,
        controlButtons: state.AdminTopics.controlButtons,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        markers: state.superadmin.markers,
        isTopicsFetching: state.AdminTopics.isTopicsFetching,
        companies: state.superadmin.allCompanies,
        isSuperadmin: state.Account.isSuperadmin,
        deviceType: state.header.deviceType
    }
}

const AdminTopicsContainer = connect(mapStateToProps, {     getValue, 
                                                            getAllTopics,
                                                            getAllMarkers,
                                                            setRightMenuComponent, 
                                                            setShowRightMenu, 
                                                            setRemoveRightMenuComponent,
                                                            setItemsCount, 
                                                            setCurrentPage,
                                                            getMarkers,
                                                            makeTopicVisibleToMarker,
                                                            getSuperadminCompanies,
                                                            setShowedItemsCount,
                                                            getAccounts
                                    })(AdminTopicsController)

export default AdminTopicsContainer