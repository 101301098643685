import React, {useState} from "react";
import ApexChartContainer from "apex-charts/apex-chart-container";
import s from "./short-analytic-line-chart.module.scss";
import moment from "moment";

const ShortAnalyticLineChart = (props) => {

    const getFields = (fields) => {
        let f = {...fields}
        let d = f.date.map(k => moment(k,"YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ssZ"));
        let first = moment(d[0], "YYYY-MM-DDTHH:mm:ssZ").subtract(4, "hours").format("YYYY-MM-DDTHH:mm:ssZ");
        d.unshift(first)
        f.date = d
        f.topic = {name: props.topic.name, id: props.topic.uuid}
        f.grouping = "HAURLY"
        return f
    }
    
    let dataCols = (props.topicRange && props.topicRange.values) && props.topicRange.values.map((k,i) => {return {name: props.getValue(props.topicRange.text[i]), data: k}});
    let labels = (props.topicRange && props.topicRange.labels.length) && props.topicRange.labels;
    let fields = props.topicRange && props.topicRange.fields ? getFields(props.topicRange.fields) : null
    const fieldsType = {seriesIndex: 'date', dataPointIndex: 'sentiment'}
    let data = {labels: labels, values: dataCols}

    return (
        <div className={s.AnalyticItem}>
            <div className={s.TitleText}><span>{props.getValue('TODAY_STATISTIC')}</span></div>
            {dataCols && <ApexChartContainer width={'100%'} height={140} getValue={props.getValue} data={data} type={'area'} colors={props.topicRange.colors} graphicType={{label: 'dayTime', data: 'sentiment', filters: props.filters}} topic={{index: props.index, topicId: props.topic.id, topicUUId: props.topicId}} fields={fields} fieldsType={fieldsType}/>}
        </div>
    )
};

export default ShortAnalyticLineChart;