import React, {useState, useEffect} from "react";
import s from './header.module.scss';
import ButtonsBlockController from "./buttons-block/buttons-block-controller";
import { ReactComponent as Logo } from '../assets/icons/logo.svg';
import { ReactComponent as MenuIcon } from '../assets/icons/menu.svg';
import { useNavigate } from "react-router-dom";
import PanelListOfPublicationsContainer from "./panel-list-of-publications/panel-list-of-publications-container";

const Header = (props) => {

    const getLanguageItems = (LanguageItems) => {
        return LanguageItems.map( k => {return {name: k.name, short_name: k.short_name, title: props.getValue(k.title)}})
    }

    const getButtonsByRole = (role) => {
        switch(role){
            case 'ROLE_USER':
            case 'ROLE_ADMIN':
                return props.rightTopButtons;
            case 'ROLE_SUPER_ADMIN':
                return props.superAdminRightTopButtons;
            case 'ROLE_MARKER':
                return props.markerRightTopButtons
            default: 
                return props.defaultRightTopButtons
        }
    }

    const getMediumButtonsByRole = (role) => {
        if(!role || role === undefined)
            return null
        switch(role){
            case 'ROLE_USER':
                return props.deviceType !== 'mobile' ? props.mediumButtons : [props.topButtons, ...props.mediumButtons];
            case 'ROLE_ADMIN':
                return props.deviceType !== 'mobile' ? props.adminMediumButtons : [...props.topButtons, ...props.adminMediumButtons];
            case 'ROLE_SUPER_ADMIN':
                return props.deviceType !== 'mobile' ? props.superAdminMediumButtons 
                : [...props.topButtons, ...props.superAdminMediumButtons, ...props.superAdminRightTopButtons]
            case 'ROLE_MARKER':
                return props.markerMediumButtons
            default: 
                return null
        }
    }

    const getTopButtonsByRole = (role) => {
        if(!role || role === undefined)
            return null
        switch(role){
            case 'ROLE_USER':
            case 'ROLE_ADMIN':
                return props.topButtons
            case 'ROLE_SUPER_ADMIN':
                return props.superAdminTopButtons
            case 'ROLE_MARKER':
                return props.markerTopButtons
            default: 
                return null
        }
    }

    const getRightBottomButtonsByRole = (role) => {
        if(!role || role === undefined)
            return null
        switch(role){
            case 'ROLE_USER':
            case 'ROLE_ADMIN':
                return props.rightBottomButton;
            case 'ROLE_SUPER_ADMIN':
                return props.superAdminRightBottomButtons;
            case 'ROLE_MARKER':
                return null
            default: 
                return null
        }
    }

    const [LanguageItems, setLanguageItems] = useState(props.LanguageItems ? getLanguageItems(props.LanguageItems) : []);
    const [rightTopButtons, setRightTopButtons] = useState(getButtonsByRole(props.role))
    const [mediumButtons, setMediumButtons] = useState(getMediumButtonsByRole(props.role))
    const [topButtons, setTopButtons] = useState(getTopButtonsByRole(props.role))
    const [rightBottomButton, setRightBottomButton] = useState(getRightBottomButtonsByRole(props.role));
    const [isShowMenu, setIsShowMenu] = useState(true)
    const [rerender, setRerender] = useState(false);

    useEffect(()=>{
        setLanguageItems(props.LanguageItems ? getLanguageItems(props.LanguageItems) : []);
        setRightTopButtons(getButtonsByRole(props.role));
        setMediumButtons(getMediumButtonsByRole(props.role))
        setTopButtons(getTopButtonsByRole(props.role))
        setRightBottomButton(getRightBottomButtonsByRole(props.role))
        setRerender(!rerender)
    }, [props.LanguageItems, props.selectedLanguage, props.rightBottomButton, props.role, props.superAdminRightTopButtons, props.mediumButtons, props.superAdminMediumButtons, props.topButtons, props.superAdminTopButtons])

    let navigate = useNavigate();

    let goto = () => {
        props.selectPage("ShortAnalytic")
        navigate("/page/");
    }

    const showMenu = () => {
        setIsShowMenu(!isShowMenu)
    }

    return(
        <div className={s.headerBlock}>   
            <div className={s.headerTop}>
                <div className={s.block}>
                    <div className={s.LogoButtonBlock}>
                        <div className={s.LogoBlock} onClick={goto}>
                            {/*<img src={logo} alt="logo"/>*/}
                            <Logo width="300" height="50" />
                            {/*<div className={s.logoText}>
                                <span>FCBK Echo</span>
                                <span>Media Monitoring</span>
                            </div>*/}
                        </div>
                        { props.deviceType === 'mobile' ? <div className={s.MenuButton} onClick={showMenu}>
                        <div><MenuIcon /></div>
                    </div>
                    : null }
                    </div>

                    {props.deviceType !== 'mobile' || !isShowMenu ? <div className={s.headerMedium}>
                    {(props.isAuth && mediumButtons) && <ButtonsBlockController type="top" 
                                                    buttons={mediumButtons} 
                                                    modalShowToggle={props.modalShowToggle} 
                                                    selectPage={goto}
                                                    getValue={props.getValue}
                                                    setClearSelected={props.clearAllSelected}
                                                    role={props.role}
                                                    pageName={props.pageName}
                                                    LanguageItems={LanguageItems}
                                                    AccountItems={props.AccountItems}
                                                    languageIcon={props.icon}
                                                    themeIcon={props.themeIcon}
                                                    themeItems={props.ThemeItems}
                                                    exit={props.exit}
                                                    exitCompany={props.removeSuperAdminCompany}
                                                    isAuth={props.isAuth}
                                                    languageCallback={props.setlocale}
                                                    selectedLanguage={props.selectedLanguage}
                                                    themeCallback={props.setSelectedMainTheme}
                                                    deviceType={props.deviceType}
    />}
                        {/*props.isAuth && <ButtonsBlockController type="headerMedium" buttons={props.mediumButtons} getValue={props.getValue}/>*/}
                    </div> : null}
                </div>

                {props.deviceType !== 'mobile' ? <div className={s.topHeaderButtons}>
                { <ButtonsBlockController type="RightTop" 
                                            buttons={rightTopButtons} 
                                            modalShowToggle={props.modalShowToggle} 
                                            selectPage={goto} 
                                            exit={props.exit}
                                            selectedLanguage={props.selectedLanguage}
                                            setSelectedLanguage={props.setSelectedLanguage}
                                            setSelectedUnAuthorizedLanguage={props.setSelectedUnAuthorizedLanguage}
                                            languageIcon={props.icon}
                                            languageCallback={props.setlocale}
                                            LanguageItems={LanguageItems}
                                            AccountItems={props.AccountItems}
                                            themeIcon={props.themeIcon}
                                            themeCallback={props.setSelectedMainTheme}
                                            themeItems={props.ThemeItems}
                                            getValue={props.getValue}
                                            isAuth={props.isAuth}
                                            role={props.role}
                                            exitCompany={props.removeSuperAdminCompany}
                                            companyId={props.companyId}
                                            accountId={props.accountId}
                                            adminCompanyId={props.adminCompanyId}
                                            setClearSelected={props.clearAllSelected}
                                            pageName={props.pageName}
                                            deviceType={props.deviceType}
                                            />}
                </div> : null}
            </div>
            {props.deviceType !== 'mobile' ? <div className={s.headerTop}>
                    <div className={s.topHeaderButtons}>
                    {(props.isAuth && topButtons) && <ButtonsBlockController type="top" 
                                                    buttons={topButtons} 
                                                    modalShowToggle={props.modalShowToggle} 
                                                    selectPage={goto}
                                                    getValue={props.getValue}
                                                    role={props.role}
                                                    setClearSelected={props.clearAllSelected}
                                                    pageName={props.pageName}
                                                    deviceType={props.deviceType}
                                                    />}
                    </div>
                    <div className={s.Notifications}>
                    {(props.isAuth && topButtons) && <ButtonsBlockController type="medium" 
                                                    buttons={rightBottomButton} 
                                                    modalShowToggle={props.modalShowToggle} 
                                                    selectPage={goto}
                                                    getValue={props.getValue}
                                                    role={props.role}
                                                    setClearSelected={props.clearAllSelected}
                                                    pageName={props.pageName}
                                                    deviceType={props.deviceType}
                                                    />}
                    </div>
            </div> : null}
            { props.isShowPanel && <PanelListOfPublicationsContainer/> }
        </div>
    )

}

export default Header;