import CompaniesTableController from "./companies-table-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import { getCompaniesTable } from "selectors/superadmin-selector";
import { deleteCompanyTopic, setCompanyId, setAccountId, setTopicId, companyStatus, createCompany, updateCompany, deleteCompany, addSuperAdminCompany } from "store/superadmin";
import { setItemsCount, setCurrentPage, setShowedItemsCount } from "store/paginator";

let mapStateToProps = (state) => {
    return {
        companyId: state.superadmin.companyId,
        companies: getCompaniesTable(state),
        accountId: state.Account.id,
        companyStatuses: state.superadmin.companyStatuses,
        companyTableHeader: state.superadmin.companyTableHeader,
        companyControlButtons: state.superadmin.companyControlButtons,
        isSuperadmin: state.Account.isSuperadmin,
        adminCompanyId: state.Account.companyId,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        companyIndex: state.superadmin.companyIndex,
        deviceType: state.header.deviceType
    }
}

const CompaniesTableContainer = connect(mapStateToProps, {  
                                getValue,
                                setCompanyId,
                                setAccountId, 
                                setTopicId,
                                deleteCompanyTopic,
                                setRightMenuComponent, 
                                setShowRightMenu, 
                                setRemoveRightMenuComponent,
                                companyStatus,
                                createCompany,
                                updateCompany,
                                deleteCompany,
                                addSuperAdminCompany,
                                setItemsCount, 
                                setCurrentPage, 
                                setShowedItemsCount
                                })(CompaniesTableController)

export default CompaniesTableContainer