import React from "react";
import s from "./short-analytic-item.module.scss";
import { ReactComponent as PreviousArrowIcon } from "../../../assets/icons/previous-arrow.svg";
import { ReactComponent as NextArrowIcon } from "../../../assets/icons/next-arrow.svg";
import { ReactComponent as OpenEyeIcon } from "../../../assets/icons/open-eye.svg";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ShortAnalyticItem = (props) => {

    const getFormatDate = (date) => {
        return moment(date).format("DD.MM.YYYY, HH:mm")
    }

    const navigate = useNavigate();

    const linkToPage = () => {
        //props.setTreeSelectedUUId(props.topicId);
        props.setSelectedSubtopicsAll([{name: props.topicName, id: props.topicId}], []);
        navigate('/page/main', { replace: true })
    }

    return (
        <div className={s.AnalyticItem}>
            <div className={s.topicTop}>
                <span onClick={linkToPage} className={s.topicName}>{props.topicName}</span>
            </div>
            <div className={s.topicBottom}>
                <div className={s.TopicDates}>
                    <div><PreviousArrowIcon /><span>{getFormatDate(props.createdAt)}</span></div>
                    {/*<div><OpenEyeIcon /><span>{props.updatedAt}</span></div>*/}
                    <div><NextArrowIcon /><span>{getFormatDate(props.updatedAt)}</span></div>
                </div>
                <div className={s.NewMessages}>
                    {/*<span className={s.NewMessagaesCount}>16</span>
                    <span className={s.NewMessagaesText}>{props.getValue('NEW_MESSAGES')}</span>*/}
                </div>
            </div>
        </div>
    )
}

export default ShortAnalyticItem;