import React, {useState} from "react";
import TableItem from "items/table/table";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import PaginatorContainer from "items/paginator/paginator-container";

const Digest = (props) => {

    let [selectedItem, setSelectedItem] = useState(null);

    const createDigest = (type) => {
        props.onTableItemsClick(type)
    }

    const selectTableITem = (item) => {
        if(selectedItem !== item){
            setSelectedItem(item, props.topics[item]);
        }
    }

    const addItemButton = <StandartButton title={props.getValue('CREATE_DIGEST')} icon={PlusIcon} fill='white' callback={createDigest.bind(this, "create")}/>

    const getHiddenColumns = (deviceType) => {
        switch(deviceType){
            case 'mobile':
                return [0,2,3,4,5,6,7]
            case 'tablet':
                return [0,2,4,5,6]
            default:
                return []
        }
    }

    return (
        <div>
            {/*<PaginatorContainer />*/}
            { <TableItem 
                                    data={[props.tableHeader, props.data]} 
                                    addItemButton={addItemButton} 
                                    getValue={props.getValue} 
                                    titleText={props.getValue('LIST_OF_DIGESTS')}
                                    //hiddenColumns={props.hiddenColumns}
                                    topics={props.topics}
                                    selectItem={selectTableITem}
                                    selectedItem={selectedItem}
                                    controllerButtons={props.controllerButtons}
                                    controlButtons={props.controlButtons}
                                    height={300}
                                    hidden={getHiddenColumns(props.deviceType)}
                                    weekDays={props.weekDays}/>}

        </div>
    )
}

export default Digest;