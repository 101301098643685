import React, {useState, useEffect} from "react";
import s from "./posts.module.scss";
import Post from "./post/post";
import Preload from "../preload/preload";
import Spinner from "items/preload/spinner";
import NewPostsCount from "./new-post-count/new-post-count";
import { StandartButton } from "commons/form-controls";
import { ReactComponent as ArrowUpIcon } from 'assets/icons/angle-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/angle-down.svg';

const PostsItem = (props) => {
    const [selected, setSelected] = useState((props.selected && props.selected.length) && props.selected);
    const [counter, setCounter] = useState(props.counter);
    const [geoplaces, setGeoplaces] = useState(props.geoplace ? props.geoplace : {})
    const [showMoreButton, setShowMoreButton] = useState(false);
    
    const getSmile = (sentiment) => {
        switch(sentiment){
            case 'NEUTRAL':
                return <SmileIcon />
            case 'NEGATIVE':
                return <NeutralSmileIcon />
        }
    }

    useEffect(() => {
        if(selected && selected.length !== props.selected.length){
            setSelected(props.selected);
            props.getPostsFromTopic(selected);
        }
        /*if(props.selectedUUId){
            const interval = setInterval(() => {
                setCounter(prevCounter => prevCounter + 1);
                props.getNewPublicationsCount(props.selectedUUId, false)
              }, 3000);
              
              return () => clearInterval(interval);
        }*/
        //setShowMoreButton(props.allShowMoreText);
        if(props.update)
            setShowMoreButton(false)
        setGeoplaces(props.geoplace ? props.geoplace : {})
    }, [props, props.allShowMoreText]);

    const changePage = (page) => {
        props.changePage(page)
    }

    const changeRowsCount = (rows) => {
        props.changeRowsCount(rows)
    }

    const getUpdatedPost = (signature, post) => {
        let f = props.updatedDocuments.length ? props.updatedDocuments.filter(u => {return u.signature === signature ? u : null}) : null
        return f.length ? f[0] : {...post}
    }

    const getTopicName = (uuid, type) => {
        let k = props.topicData.find((f,i)=>{return f.uuid === uuid})
        return (k && k[type]) ? k[type] : null
    }

    const setShowMoreTextButton = (show) => {
        setShowMoreButton(show)
    }

    const posts = props.posts.docs.map((post, i) => {
        let selected = props.selectedPostItems.indexOf(post.signature) > -1 ? true : false

        return <Post 
                    key={i}
                    role={props.role}
                    getValue={props.getValue} 
                    { ...post} 
                    setSentimentType={props.setSentimentType} 
                    modalShowToggle={props.modalShowToggle} 
                    setAddSelectedMyFilter={props.setAddSelectedMyFilter} 
                    filtersChanged={props.filtersChanged} 
                    selectedMyFilter={props.selectedMyFilter} 
                    applyedFilters={props.applyedFilters} 
                    getLocations={props.getLocations}
                    selectedLanguage={props.selectedLanguage}
                    setClearUpdatedDocuments={props.setClearUpdatedDocuments}
                    getTagName={props.getTagName}
                    removeTagFromDocument={props.removeTagFromDocument}
                    setDocumentStatus={props.setDocumentStatus}
                    setAuthorsStatus={props.setAuthorsStatus}
                    addMarkerProfileToBlackList={props.addMarkerProfileToBlackList}
                    profileFromBlackList={props.profileFromBlackList}
                    addFavouriteToTopic={props.addFavouriteToTopic}
                    selectedTopicId={props.selectedId}
                    setSelectedPostItems={props.setSelectedPostItems}
                    selectedPost={selected}
                    selectedTopics={props.selectedTopics}
                    setDocumentFavouriteStatus={props.setDocumentFavouriteStatus}
                    setDomianStatus={props.setDomianStatus}
                    postType={props.postType}
                    selectedSubtopics={props.selectedSubtopics}
                    removeFromBlackList={props.removeFromBlackList}
                    topicIdNumber={getTopicName(post.topicId, 'id')}
                    profileToBlackList={props.profileToBlackList}
                    setSelectAllItems={props.setSelectAllItems}
                    showMoreButton={showMoreButton}
                    setShowMoreTextButton={setShowMoreTextButton}
                />
    })

    const reload = () => {
        props.getDocumentsByType(props.page, props.topicsSelected, true);
        props.filtersChanged(true)
        //this.props.setIsFilterChanged(isChanged, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), (this.props.type && this.props.type !== 'Documents') ? this.props.selectedTopics : this.props.selectedUUId, this.state.filtersFields, [subtopics]);
        props.resetCounter()
    }

    const postBlock = <div className={s.PostsBlock}>
           { posts }
           {showMoreButton ? <div className={s.ShowMoreButton}>
                <StandartButton icon={ArrowDownIcon} stroke={'white'} callback={setShowMoreTextButton.bind(this, false)}/>
            </div> : null}
        </div> 
        
    return (
        <React.Fragment>
            {props.type === 'Documents' ? <NewPostsCount 
                getNewPublicationsCount={props.getNewPublicationsCount} 
                getSubtopicsNewPublicationsCount={props.getSubtopicsNewPublicationsCount} 
                reload={reload} 
                newPostsCount={props.newPostsCount} 
                selectedTopics={props.selectedTopics} 
                selectedSubtopics={props.selectedSubtopics} 
                getValue={props.getValue} 
                newPostsIsFetching={props.newPostsIsFetching}
                statisticsError={props.statisticsError}
                deviceType={props.deviceType}
            /> : null }
             
            <div className={s.PostsItem}>
                
                { props.isFetching 
                    ? <Spinner />
                    : postBlock       
                }
            </div>
        </React.Fragment>
    )
}

export default PostsItem;