import MultiPostPanelController from "./multi-post-panel-controller";
import { connect } from "react-redux";
import { getStartDate, getEndDate } from "selectors/calendar-selector";
import { getValue } from "store/languages";
import { SentimentsOfList, setRemoveItemFromTags, setDocumentStatus, updateSentimentByFilter, setStatusByFilter, setDocumentFavouriteStatus, setDocumentFavouriteFilterStatus, updateFavouriteMultipleTopics, updateSentimentMultipleTopics, updateStatusMultipleTopics, updateTagsMultipleTopics } from "store/storage";
import { modalShowToggle } from "store/modal";
import { getTopicName} from "selectors/theme-selector";
import { getPostsSignatures } from "selectors/storage-selector";
import { getSelectedTopicsUUID, getSelectedSubTopicsUUID } from "selectors/topic-menu-selector";

let mapStateToProps = (state) => {
    return {
        role: state.Account.role,
        selectedPostItems: state.storage.selectedPostItems,
        selectedAllList: state.storage.selectedAllList,
        documentListTags: state.storage.selectedPostTags,
        tags: state.Tags.tags,
        selectedUUId: state.Tree.selectedUUId,
        selectedSubUUId: state.Tree.selectedSubUUId,
        selectedItems: state.Filters.selectedItems,
        selectedTopics: getSelectedTopicsUUID(state),
        selectedTopicsIds: state.Filters.selectedTopicsIds,
        selectedMyFilter: state.Filters.selectedMyFilter,
        startDate: getStartDate(state),
        endDate: getEndDate(state),
        grouping: state.Calendar.grouping,
        topics: getTopicName(state),
        posts: getPostsSignatures(state),
        searchList: state.SearchText.searchList,
        deviceType: state.header.deviceType
    }
}

export default connect(mapStateToProps, {getValue, SentimentsOfList, modalShowToggle, setRemoveItemFromTags, setDocumentStatus, updateSentimentByFilter, setStatusByFilter, setDocumentFavouriteStatus, setDocumentFavouriteFilterStatus, updateFavouriteMultipleTopics, updateSentimentMultipleTopics, updateStatusMultipleTopics, updateTagsMultipleTopics})(MultiPostPanelController)