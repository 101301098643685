import { TopicService } from "../connection/topics-service";
import { MarkerService } from "connection/marker-service";
import { checkErrorMessage, getErrorMessage } from "./errors";

const SET_TOPIC_BLACKLIST = "SET-TOPIC-BLACKLIST";
const SET_BLACKLIST_IS_FETCHING = "SET-BLACKLIST-IS-FETCHING";
const SET_BlACKLIST_IS_UPDATED = "SET-BLACKLIST-IS-UPDATED";
const SET_CLEAR_BLACKLIST = 'SET-CLEAR-BLACKLIST'


const blackList = {
    topicBlackList: [],
    tableHeader: ['#', '#', 'TOPIC', 'AUTHORS_NAME', 'PROFILE_URL', 'RESOURCE_URL', 'SOURCE_TYPE' ],
    isFetching: false,
    isUpdated: false,
}


const Blacklist = (state = blackList, action) => {
    switch(action.type){
        case SET_TOPIC_BLACKLIST:
            return {...state, topicBlackList: action.topicBlackList, isUpdated: action.last}
        case SET_BLACKLIST_IS_FETCHING:
            return {...state, isFetching: action.isFetching}
        case SET_BlACKLIST_IS_UPDATED:
            return {...state, isUpdated: action.isUpdated}
        case SET_CLEAR_BLACKLIST:
            return {...state, topicBlackList: {}}
        default:
            return {...state}
    }
}

export default Blacklist

export const setTopicBlackList = (blackList, last) => {
    
    return {
        type: SET_TOPIC_BLACKLIST,
        topicBlackList: blackList,
        last: last
    }
}

export const setBlackListIsFetching = (isFetching) => {
    return {
        type: SET_BLACKLIST_IS_FETCHING,
        isFetching: isFetching
    }
}

export const setBlacklistIsUpdated = (isUpdated) => {
    return {
        type: SET_BlACKLIST_IS_UPDATED,
        isUpdated: isUpdated
    }
}

export const setClearBlacklist = () => {
    return {
        type: SET_CLEAR_BLACKLIST,
    }
}

export const getTopicBlackList = (topicId, isMarker = false) => {
    return (dispatch) => {
        dispatch(setBlackListIsFetching(true))
        //dispatch(setBlacklistIsUpdated(true))
        let datas = [];
        let up = false
        dispatch(setBlacklistIsUpdated(true))
            if(!isMarker){
                TopicService.getTopicsBlackList(topicId).then(data => {
                    dispatch(setTopicBlackList(data.data, false))
                }).catch(error => {
                    dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
                });
            }
           else{
            MarkerService.getMarkerTopicBlackList(topicId).then(data => {
                dispatch(setTopicBlackList(data.data, false));
            }).catch(error => {
                dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
            });
           }
        dispatch(setBlackListIsFetching(false))
    }
}

export const addMarkerProfileToBlackList = (topicId, url, domain = null) => {
    return (dispatch) => {
        MarkerService.addMarkerProfileToBlackList(topicId, url, domain).then( data => {

        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}

export const removeFromBlackList = (topicId, blacklistId) => {
    return (dispatch) => {
        MarkerService.removeMarkerProfileFromBlackList(topicId, blacklistId).then( data => {
        }).catch(error => {
            dispatch(checkErrorMessage({code: error.response.status, message: getErrorMessage(error)}))
        });
    }
}