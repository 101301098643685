import React from "react";
import SpecialPage from "./special-pages";

import DropdownWithSearchContainer from "items/dropdown-with-search/dropdown-with-search-container";
import TopicMenuContainer from "pages/topic-menu/topic-menu-container";
import PeriodCalendarContainer from "items/period-calendar/period-calendar-container";
import SearchTextContainer from "items/search-text/search-text-container";
import SortFilterContainer from "items/sort-filter/sort-filter-container";
import FiltersContainer from "items/filters/filters-container";
import MyFiltersContainer from "items/myFilters/my-filters-container";
import { getFormatedDate } from "service/format-date";
import moment from 'moment';

class SpecialPageController extends React.Component{
    constructor(props){
        super(props);
        this.selectedTopics = [];
        this.searchList = {};
        this.selectedMyFilter = this.props.selectedMyFilter.topics && this.props.selectedMyFilter.topics !== undefined ? this.props.selectedMyFilter.topics : {}
        this.state = {
            selectedTopic: 0,
            //selectedTopics: (this.props.selectedMyFilter.topics && this.props.selectedMyFilter.topics !== undefined) ? this.props.selectedMyFilter.topics.join(',') : '',
            selectedTopics: this.props.selectedTopics && this.props.selectedTopics.join(','),
            topics: this.props.selectedTopics && this.props.selectedTopics.join(','),
            pageName: this.props.type,
            update: false,
            isFirst: true,
            selectedAllList: this.props.selectedAllList,
            //type: this.props.type,
            selectedPostItems: this.props.selectedPostItems.join(",")
        }
    }

    setTopicsFilter = () => {
        if(this.props.topics){
            if(this.props.type !== 'Documents'){
                let topicsName = this.props.topics && this.props.topics.map( k => k.name);
                let topicsUUId = this.props.topics && this.props.topics.map( k => k.uuid);
                let topicsId = this.props.topics && this.props.topics.map( k => k.id);

                this.props.setMySelectedTopicsFilter({['topics']: topicsName}, 'topics');
                this.props.setSelectedTopics(topicsUUId,topicsId)
                this.props.filtersChanged(true)
                this.setState({
                    update: true
                })
            }        
            else{
                this.props.setMySelectedTopicsFilter({}, '');
                this.props.filtersChanged(true)
                this.setState({
                    update: true
                })
            }

        }

    }

    setSelected = () => {
        if(this.props.type !== 'Documents' && this.props.selectedTopics && this.props.topicData && this.state.isFirst){
            let uuids = this.props.topicData.map(k => k.uuid)
             this.props.setMySelectedFilter({'topics': uuids}, 'ids')
        }
    }

    checkDatePeriodToOneDay = (startDate, endDate) => {
        let s = moment(startDate, "YYYY-MM-DDTHH:mm:ssZ")
        let e = moment(endDate, "YYYY-MM-DDTHH:mm:ssZ")
        if(e.add(1, 'days').isAfter(s)){
            let start = moment().subtract(6, 'days')
            start.startOf('day')
            this.props.setStartDate(new Date(start.format('YYYY-MM-DDTHH:mm:ssZ'))) 
            this.props.setEndDate(new Date(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')))
            this.props.setGrouping("DAILY")
            this.setState({
                grouping: "DAILY"
            })
        }
    }

    componentDidMount(){
        if(this.props.deviceType !== 'mobile') this.props.setShowLeftMenu(true);
        if(this.props.deviceType !== 'mobile') this.props.setShowRightMenu(true);

        if(this.props.role){
            this.props.getMyTopics(this.props.role);
        }
        else{
            this.props.getMe();
        }
        this.props.setThemes(this.props.topicData);

        if(this.props.role && this.props.role !== 'ROLE_MARKER')
            this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)
        else
            this.props.getMe();

        this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'period'}/>, 'periodCalendar');
        this.props.setRightMenuComponent(<SearchTextContainer />, 'searchText')
        this.props.setRightMenuComponent(<SortFilterContainer />, 'sorting')
        this.props.setShowPanel(true, true)
        this.props.setPageName(this.props.type)
        //setTimeout(() => {this.setTopicsFilter()}, 300) 
        //this.props.getTopicDocuments("eb86f7b4-cf5d-429d-a587-47fbc05b113c", 10, 0)
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    setLeftMenu = (topicData, subtopics = null) => {
        
    }

    checkSubtopics = () => {
        if(this.props.topicData && this.props.topicData[this.props.selectedTopic].id !== this.props.subtopics[0].topicId ){

        }
    }

    getFiltersList = (startDate, endDate, topics, fields, subtopics, filters) => {
        switch(this.props.type){
            case 'BlackList':
                topics.length && this.props.getBlackListFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            case 'RecycleBin':
                topics.length && this.props.getDeletedFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;//getFavouriteManualFilters
            case 'FavouritePublications':
                topics.length && this.props.getFavouriteManualFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            case 'Favourite':
                topics.length && this.props.getFavouriteAutoFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
            default:
                topics.length && this.props.getTopicsFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);
                break;
        }  
    }

    /*shouldComponentUpdate(nextProps, nextState){
        if(nextProps.role && (!nextProps.topics || nextProps.topics === undefined)){
            this.props.getMyTopics(nextProps.role)
            return false
        }
        if(nextProps.leftMenuComponent.findIndex( c => c.componentName === 'topics') === -1 && nextProps.topics && nextProps.topicData.length){
            this.props.setLeftMenuComponent(
                <TopicMenuContainer 
                        items={nextProps.topicData}
                        selected={nextProps.selectedTopics}
                        type={'checkboxes'}
                        />,
            'topics', true)             
        }
    }*/

        shouldComponentUpdate(nextProps, nextState){
            if(nextProps.role && (!nextProps.topics || nextProps.topics === undefined)){
                this.props.getMyTopics(nextProps.role)
                return false
            }
            if(nextProps.pageName !== nextState.pageName){
                this.props.setPageName(nextProps.type)
                this.setState({
                    pageName: nextProps.pageName,
                    type: nextProps.type
                })
            }
            if(nextProps.leftMenuComponent.findIndex( c => c.componentName === 'topics') === -1 && nextProps.topics && nextProps.topicData.length){
                this.props.setLeftMenuComponent(
                    <TopicMenuContainer 
                            items={nextProps.topicData}
                            selected={nextProps.selectedTopics}
                            type={'checkboxes'}
                            />,
                'topics', true)             
            }
            if(nextProps.topicData && nextProps.topicData.length && nextProps.selectedTopics && nextState.isFirst){
                this.setState({
                    isFirst: false
                })
            }
            if( nextProps.role !== nextState.role 
                || nextProps.selectedTopics.join(",") !== nextState.selectedTopics 
                || nextProps.topics && (nextProps.topics.length !== nextState.topics) 
                || nextProps.selectedAllList !== nextState.selectedAllList 
                || nextProps.type !== nextState.pageName
                //|| nextState.isFirst
                || nextProps.selectedPostItems && nextProps.selectedPostItems.join(",") !== nextState.selectedPostItems){
                    
                if(nextProps.topicData && nextProps.topicData.length){
                    if(nextState.isFirst && (!nextProps.selectedTopics || !nextProps.selectedTopics.length)){
                        let t = nextProps.topics.map((k,i) => {return {name: k.name, id: k.uuid}})
                        this.props.setSelectedTopicsAll(t)

                        this.setState({
                            isFirst: false
                        })
                    }
                    if(nextProps.selectedTopics && nextProps.selectedTopics.length){
                        
                        this.props.setRightMenuComponent(<FiltersContainer type={nextProps.type} topics={nextProps.topicData[0]} getFiltersList={this.getFiltersList}/>, 'filters')
                        //this.props.setRightMenuComponent(<FiltersContainer type={nextProps.type} topics={(nextProps.type !== 'Documents' || !nextProps.type) ? nextProps.selectedTopics : nextProps.topics[nextProps.selectedTopic]} getFiltersList={this.getFiltersList}/>, 'filters');
                    }
                    else{
                        this.props.setRightMenuComponent(<div></div>, 'filters')
                    }
                }
                
            if(nextProps.topicData && nextProps.topicData.length){
                if(nextState.isFirst && (!nextProps.selectedTopics || !nextProps.selectedTopics.length)){
                    let t = nextProps.topics.map((k,i) => {return {name: k.name, id: k.uuid}})
                    this.props.setSelectedTopicsAll(t)

                    this.setState({
                        isFirst: false
                    })
                }
                if(nextProps.selectedTopics && nextProps.selectedTopics.length){
                    this.props.setPageName(this.props.type)
                    this.props.setRightMenuComponent(<FiltersContainer type={nextProps.type} topics={nextProps.topicData[0]} getFiltersList={this.getFiltersList}/>, 'filters')
                    //this.props.setRightMenuComponent(<FiltersContainer type={nextProps.type} topics={(nextProps.type !== 'Documents' || !nextProps.type) ? nextProps.selectedTopics : nextProps.topics[nextProps.selectedTopic]} getFiltersList={this.getFiltersList}/>, 'filters');
                }
                else{
                    this.props.setRightMenuComponent(<div></div>, 'filters')
                }
            }
            


            this.props.setSelectedFiltersTopics(nextProps.selectedTopics)
            this.setState({
                role: nextProps.role,
                selectedTopics: nextProps.selectedTopics.join(","),
                topics: nextProps.topics && nextProps.topics.length ? nextProps.topics.length : 0,
                selectedAllList: nextProps.selectedAllList,
                type: nextProps.type,
                selectedPostItems: nextProps.selectedPostItems ? nextProps.selectedPostItems.join(",") : "",
                //isFirst: false
            })
            return true
        }
        else return false
    }

    componentWillUnmount(){
        //this.props.clearSearchList();
        this.props.setRemoveLeftMenuComponent();
        this.props.setRemoveRightMenuComponent();
        //this.props.setExtendedAnalyticComponent(null);
        this.props.setShowLeftMenu(false);
        this.props.setShowRightMenu(false);
        this.props.setShowPanel(false);
        //this.props.setSelectedMenu(0)
        //this.props.setThemeTableData(null);//clear topic
        this.props.setSubtopics([]);//clear subtopic
        //this.props.setSelectedTopics([],[]);
        //this.props.clearSearchList();
        this.props.setMySelectedFilter({topics: []}, 'topics');
    }

    render(){
        if(this.props.selectedTopics && this.props.selectedTopics.length){
            return <SpecialPage topicData={this.props.topicData} {...this.props} setLeftMenu={this.setLeftMenu}/>
        }
    }

}

export default SpecialPageController;