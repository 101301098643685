import AdminMarkersController from "./admin-markers-controller";
import { connect } from "react-redux";
import { getValue } from "store/languages";
import { getAllMarkers, setTopicsCount, setTopicsPage } from "store/admin-markers";
import { getAllTopics, setSearchText, setStartSearch } from "store/admin-topics";
import { setRightMenuComponent, setShowRightMenu, setRemoveRightMenuComponent } from "store/side-bar";
import {getAdminTableFromState} from "selectors/admin-topics-selector"
import { setItemsCount, setCurrentPage, setShowedItemsCount } from "store/paginator";
import { getMarkers, makeTopicVisibleToMarker} from "store/superadmin"
import { getAllMarkersSelector } from "selectors/superadmin-selector";

let mapStateToProps = (state) => {

    return {
        adminTopics: state.AdminTopics.topics,
        adminMarkers: getAllMarkersSelector(state),
        tableHeader: state.AdminMarkers.tableHeader,
        controlButtons: state.AdminMarkers.controlButtons,
        itemsCount: state.paginator.itemsCount,
        currentPage: state.paginator.currentPage,
        showedItemsCount: state.paginator.showedItemsCount,
        markers: state.superadmin.markers,
        topicsCount: state.AdminMarkers.topicsCount,
        topicsPage: state.AdminMarkers.topicsPage,
        startSearch: state.AdminTopics.startSearch,
        searchText: state.AdminTopics.searchText,
        deviceType: state.header.deviceType
        
    }
}

const AdminMarkersContainer = connect(mapStateToProps, {     getValue, 
                                                            getAllMarkers,
                                                            getAllTopics,
                                                            setRightMenuComponent, 
                                                            setShowRightMenu, 
                                                            setRemoveRightMenuComponent,
                                                            setItemsCount, 
                                                            setCurrentPage,
                                                            getMarkers,
                                                            setTopicsCount, 
                                                            setTopicsPage,
                                                            setShowedItemsCount,
                                                            makeTopicVisibleToMarker,
                                                            setSearchText, 
                                                            setStartSearch
                                    })(AdminMarkersController)

export default AdminMarkersContainer