import React, { useState, useEffect } from "react";
import { StandartButton } from "commons/form-controls";
import TableItem from "items/table/table";
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import PaginatorContainer from "items/paginator/paginator-container";
import s from "../admin.module.scss";

const AdminAccounts = (props) => {
    const [accounts, setAccounts] = useState(props.accounts ? props.accounts : null);
    const [companyId, setCompanyId] = useState(props.companyId ? props.companyId : null);
    const [selectedItem, setSelectedItem] = useState(props.selected);

    const selectTableItem = (item) => {
        if(!props.isTopicAccounts){
            //setSelectedItem(item);
            props.setSelected(item)
            props.setAccountId((props.accounts && props.accounts.accounts) && props.accounts.accounts[item].id)
        }
    }

    const addNewCompany = () => {
        props.showRightMenu('create')
    }

    useEffect(()=>{
        setAccounts(props.accounts ? props.accounts : null)
        setCompanyId(props.companyId ? props.companyId : null)
        setSelectedItem(props.selected)
    }, [props.accounts, props.companyId, props.selected])

    const editAccount = (account) => {
        props.showRightMenu('edit', 'EDIT_ACCOUNT', companyId, account)
    }

    const deleteAccount = (account) => {
        props.deleteAccount(props.companyId, account.id)
    }

    const addAccountToCompany = () => {
        if (companyId !== null || companyId !== '') {
            props.showRightMenu('create', 'ADD_ACCOUNT', companyId, null)
        } else {
            props.showRightMenu('create', 'ADD_ACCOUNT', null, null)
        }
    }

    const addItemButton = !props.isTopicAccounts ? <StandartButton 
        icon={PlusIcon} 
        fill={'white'}
        title={props.isTopicAccounts ? props.getValue('USERS') : props.getValue("CREATE_USER")}
        callback={addAccountToCompany}
    /> : null

    return (
        <div className={s.Admin}>
            {props.isSuperadmin && !companyId ? <PaginatorContainer /> : null}
            <TableItem 
                data={[props.tableHeader, props.data]}
                height={320}
                addItemButton={addItemButton}
                
                selectItem={selectTableItem}
                selectedItem={selectedItem}
                hidden={props.deviceType === 'mobile' ? [2,3,4,5,7] : []}         
            />
        </div>
    )
}

export default AdminAccounts;