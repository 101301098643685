import LeftMenuController from "./left-menu-controller";
import { connect } from "react-redux";
import { compose } from "redux";
import { withAuthRedirect } from "../../../hoc/AuthRedirect";
import { setShowLeftMenu } from "../../../store/side-bar";
import { getValue } from "../.../../../../store/languages";

let mapStateToProps = (state) => {
    return {
        showLeftMenu: state.SideBarMenu.showLeftMenu,
        leftMenuComponent: state.SideBarMenu.leftMenuComponent,
        deviceType: state.header.deviceType
    }
}

export default connect(mapStateToProps, {setShowLeftMenu, getValue})(LeftMenuController)