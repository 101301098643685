import React from "react";
import CompaniesTable from "./companies-table";
import ControlButtons from "items/control-buttons/control-buttons";
import CreateCompany from "../create-company/create-company";

class CompaniesTableController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selected: null
        }
    }

    closeRightMenu = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    CreateComapany = (name, bin, maxNumTopics, status, expiryDate) => {
        this.props.createCompany(name, bin, maxNumTopics, status, expiryDate, 0, this.props.showedItemsCount);
        this.closeRightMenu();
    }

    editCompany = (companyId, companyName, companyBin, companyTopicsCount, companyStatus, expiryDate) => {
        
        this.props.updateCompany(companyId, companyName, companyBin, companyTopicsCount, companyStatus, expiryDate, this.props.currentPage, this.props.showedItemsCount)
        this.closeRightMenu();
    }

    deleteCompany = (companyId) => {
        this.props.deleteCompany(companyId, this.props.currentPage, this.props.showedItemsCount)
    }

    closeMenu = () => {
        this.props.setRemoveRightMenuComponent()
    }

    showRightMenu = (show, title, index) => {
        
        this.closeRightMenu();
        let company = this.props.companies.companies[index];
        
        if(show){
            this.props.setShowRightMenu(true)
            switch(show){
                case 'create':
                    this.props.setRightMenuComponent(
                        <CreateCompany 
                            type={'create'} 
                            isEdit={true}
                            companyStatuses={this.props.companyStatuses} 
                            callback={this.CreateComapany} 
                            getValue={this.props.getValue}
                        />, 
                        'company', 
                        null, 
                        this.props.getValue('CREATE_COMPANY'), 
                        true,
                        null,
                        this.closeMenu
                    );
                    break;
                case 'edit':
                    this.props.setRightMenuComponent(
                        <CreateCompany 
                            type={'edit'} 
                            company={company} 
                            isEdit={true}
                            companyStatuses={this.props.companyStatuses} 
                            callback={this.editCompany} 
                            getValue={this.props.getValue}
                        />, 
                        'company', 
                        null, 
                        this.props.getValue('EDIT_COMPANY'), 
                        true,
                        null,
                        this.closeMenu
                    );
                    break;
                case 'show':
                    this.props.setRightMenuComponent(
                        <CreateCompany 
                            company={company} 
                            isEdit={false} 
                            companyStatuses={this.props.companyStatuses} 
                            callback={this.CreateComapany} 
                            getValue={this.props.getValue}
                        />, 
                        'company', 
                        null, 
                        this.props.getValue('COMPANY'), 
                        true,
                        null,
                        this.closeMenu
                    );
                    break;
                case 'company':
                    break;
            }
        }
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;
        let selectedTopic = null
        /*

        this.props.pauseTopic(key);
                break;
            case 'PAUSED':
                this.props.startTopic(key);
        */
        switch(type){
            case 'show':
                this.showRightMenu('show', key, index)
                break;
            case 'create':
                break;
            case 'edit':
                this.showRightMenu('edit', key, index)
                break;
            case 'delete':
                this.deleteCompany(key)
                break;
            case 'copy':
                break;
            case 'ACTIVE':
                setTimeout(()=>{
                    this.props.companyStatus(key, 'deactivate', this.props.currentPage, this.props.showedItemsCount);
                }, 700) 
                break;
            case 'PAUSED':
                setTimeout(()=>{
                    this.props.companyStatus(key, 'activate', this.props.currentPage, this.props.showedItemsCount);
                }, 700) 
                break;
            case 'company':
                if(this.props.companies.companies[index].status !== 'BLOCKED' && this.props.companies.companies[index].status !== 'DELETED'){
                    this.props.addSuperAdminCompany(key, this.props.accountId)
                }
                break;
        }
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        c = [...items];
        c.unshift(status)
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            if(typeof(k[k.length-1]) !== "object"){
                let id = k[0];
                k[0] = i+1;
                let status = k[3] === 'BLOCKED' ? 'PAUSED' : (k[3] === 'ACTIVE' ? 'ACTIVE' : 'PAUSED');
                k[3] = this.props.getValue(k[3])
                let ctrl = this.props.companyControlButtons;
                k.push(this.getControlButtonItems(id, status, ctrl, i))
                //k.push(this.getControlButtonItems(id, status, ctrl, i))
            }
            return k
        })
    }

    getTableHeader = (header) => {
        return this.props.deviceType === 'mobile' ? header.filter((k,i) => [2,3,4].indexOf(i) === -1) : header.map(k=> this.props.getValue(k))
    }

    render(){
        return (
            <CompaniesTable 
                            companies={this.props.companies} 
                            setCompanyId={this.props.setCompanyId} 
                            showRightMenu={this.showRightMenu} 
                            getValue={this.props.getValue}
                            setAccountId={this.props.setAccountId}
                            setTopicId={this.props.setTopicId}
                            tableHeader={this.getTableHeader(this.props.companyTableHeader)}
                            data={(this.props.companies.data && this.props.companies.data.length) && this.setTableValues(this.props.companies.data)}
                            isSuperadmin={this.props.isSuperadmin}
                            adminCompanyId={this.props.adminCompanyId}
                            selected={this.props.companyIndex}
                            hiddenColumns={this.props.deviceType === 'mobile' ? [2,3,4] : []}
                        />
        )
    }
}

export default CompaniesTableController;