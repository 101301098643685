import React from "react";
import s from "./mobile-menu.module.scss";
import { StandartButton } from "commons/form-controls";
import { NavLink } from "react-router-dom";

const MobileMenu = (props) => {

    const buttonClick = (type, link, name) => {
        props.callback(type, link, name)
    }

    //const buttons = props.menu.map((k, i) => k.name !== 'Languages' && k.name !== 'Account' ? <StandartButton key={i} title={props.getValue(k.base_name)} callback={buttonClick.bind(this, k.type, k.link ? k.link : k.name, k.name)}/> : null /*<button key={i}>{k.name}</button>*/)
    const buttons = props.menu.map((k, i) => k.name !== 'Languages' && k.name !== 'Account' 
                                        ? <button onClick={buttonClick.bind(this, k.type, k.link ? k.link : k.name, k.name)}>{k.type === 'nav' ? <NavLink to={k.link}>{props.getValue(k.base_name)}</NavLink> : <div>{props.getValue(k.base_name)}</div>}</button> 
                                        : null)
    return <div className={s.MobileMenu}>
        {buttons}
        {!props.isSuperadmin ? <button onClick={buttonClick.bind(this, 'modal', 'Exit', 'Exit')}><div>{props.getValue('EXIT')}</div></button> : null}
    </div>
}

export default MobileMenu