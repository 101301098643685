import React from "react";
import TableItem from "../../items/table/table";
import s from "./black-list.module.scss";

const BlackList = (props) => {
    const getHiddenColumns = (deviceType) => {
        switch(deviceType){
            case 'mobile':
                return [0,1,4,5,6]
            case 'tablet':
                return [0,1,2,3,4]
            default:
                return [1]
        }
    }

    return (
        <div className={s.BlackList}>
            <TableItem 
                data={[props.tableHeaderName, props.topicBlackList]}  
                getValue={props.getValue} 
                titleText={props.getValue('BLACK_LIST')}
                //hiddenColumns={[1]}
                height={230}
                hidden={getHiddenColumns(props.deviceType)}
            />
        </div> 
    )
}

export default BlackList;