import React from "react";
import ReportFormContainer from "./report-form/report-form-container";
import ReportTableContainer from "./report-table/report-table-container";
import CheckboxListContainer from "items/checkbox-list/checkbox-list-container"
import s from "./reports.module.scss"

const Reports = (props) => {

    const getBlock = () => {
        if(props.selectedRole === "ROLE_ADMIN"){
            return <ReportTableContainer />
        }
        else{
            return props.deviceType !== 'mobile' ? <ReportFormContainer /> : <CheckboxListContainer callback={props.onSend} />
        }
    }

    return (
        <div style={{display: 'flex'}} className={s.ReportsPage}>
            {getBlock()}
        </div>
    )
}

export default Reports