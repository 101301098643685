import React from "react";
import SwitchAdminMode from "./switch-admin-mode";

class SwitchAdminModeController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedRole: props.selectedRole
        }
    }

    closeItem = () => {
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        //this.props.setRemoveLeftMenuComponent()
        this.props.setRemoveRightMenuComponent();
    }

    componentDidMount(){

    }

    componentDidUpdate(){
        
        if(this.props.selectedRole !== this.state.selectedRole && this.props.pageType !== "Notifications"){
            this.closeItem();
            if(this.props.selectedRole === 'ROLE_ADMIN'){
                
                this.props.getAccounts();
            }
            this.setState({
                selectedRole: this.props.selectedRole
            })
        }
    }

    render(){
        return(
            <SwitchAdminMode 
                getValue={this.props.getValue}
                selectedRole={this.props.selectedRole}
                setSelectedRole={this.props.setSelectedRole}
                deviceType={this.props.deviceType}
            />
        )
    }
}

export default SwitchAdminModeController;