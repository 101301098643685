import React from "react";
import ShortAnalyticChartItem from "./short-analytic-chart-item";
import ShortAnalyticMobileChartItem from "./short-analytic-mobile-chart-item";
import { ArrowUp, ArrowDown } from "commons/icon-contols";

class ShortAnlayticChartItemController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data: props.data,
            deviceType: props.deviceType
        }
    }

    componentDidMount(){
        if(this.props.topicIds){
            this.props.getShortAnalytic(this.props.topicIds, this.props.mediaType)
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if((nextProps.data && nextProps.data.topicId !== nextState.data.topicId) || nextProps.deviceType !== nextState.deviceType){
            this.setState({
                data: nextProps.data,
                deviceType: nextProps.deviceType
            })
            return true
        }
        else return false
    }

    getArrowByValues = (prev, now, size=null, color=null) => {
        return prev < now ? <ArrowUp fontSize={size} color={color}/> : <ArrowDown fontSize={size} color={color}/>;
    }

    getFormattedCount = (num) =>{
        return Math.abs(num) > 999 
            ? (Math.abs(num) > 999999 
                ? Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'KK' 
                : Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'K')
            : Math.sign(num)*Math.abs(num)
    }

    render(){
        return (this.props.data && this.props.data.topicId) ? (this.props.deviceType !== 'mobile'
            ? <ShortAnalyticChartItem {...this.props} getFormattedCount={this.getFormattedCount} getArrowByValues={this.getArrowByValues}/> 
            : <ShortAnalyticMobileChartItem {...this.props} getFormattedCount={this.getFormattedCount} getArrowByValues={this.getArrowByValues}/>) : <div className="AnalyticItem"></div>
    }

}

export default ShortAnlayticChartItemController;