import React from "react";
import s from "./settings.module.scss";

const SettingsPage = (props) => {
    return (
        <div className={s.SettingsModule}>
            {( props.component) && props.component}
        </div>
    )
}

export default SettingsPage;