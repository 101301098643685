import React, { useState } from "react";
import TableItem from "items/table/table";

const CompanyTopicsTable = (props) => {
    const [selectedItem, setSelectedItem] = useState(null);

    const selectTableItem = (item) => {
        if(!props.isAccountTopics){
            setSelectedItem(item);
            props.setTopicId(props.companyTopics.topics[item].id)
        }
    }

    return (
        <div>
            { <TableItem 
                        data={[props.tableHeader, props.data]}
                        height={320}
                        hiddenColumns={props.hiddenColumns}
                        selectItem={selectTableItem}
                        selectedItem={selectedItem} 
                        hidden={props.deviceType === 'mobile' ? [2,3,4,5,6,7,8] : []}        
            />}

        </div>
    )
}

export default CompanyTopicsTable