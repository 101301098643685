import React from "react";
import Telegram from "./telegram";
import TelegramItemContainer from "./telegram-item/telegram-item-container";
import ControlButtons from "../../../../items/control-buttons/control-buttons";
import TelegramViewItem from "./telegram-view-item/telegram-view-item";

class TelegramController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedTelegramItem: this.props.selectedTelegramItem ? this.props.selectedTelegramItem.id : null,
            hiddenColumns: [6],
        }
    }

    closeMenu = () => {
        this.props.setRemoveRightMenuComponent()
    }

    showRightMenu = (show, title, topic) => {
        if(show){
            this.props.setShowRightMenu(true)
            this.props.setSubtopicItem([])
            //if(topic && (topic.showType === 'edit' || topic.showType === 'create')){
                setTimeout(()=>{this.props.setRightMenuComponent(<TelegramItemContainer topic={topic}/>, 'telegram', null, title, true, null, this.closeMenu);},300)
            /*}
            else{
                this.props.setRightMenuComponent(<TelegramViewItem getValue={this.props.getValue} topic={topic}/>, 'telegram', null, title, true);
            }*/
        }
        else{
            this.props.setRightMenuComponent(null);

        }
    }

    deleteSubscription = (subscriptionId) => {
        this.props.deleteSubscription(subscriptionId);
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        
        c = [...items];
        c.unshift(status)
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    getFilterName = (id) => {
        return this.props.myFilters.find((f,i)=>{return f.id === id}) 
    }

    getSubtopicName = (id) => {
        return this.props.subtopics ? this.props.subtopics.filter(k=>k.id === id) : null
    }

    getSubtopic = (uuid, subtopics) => {
        let n = subtopics ? subtopics.findIndex( k => k.uuid === uuid) : null
        if(n !== -1)
            return subtopics[n]
        else return ''
    }

    getTopic = (topicId) => {
        let n = this.props.topics && this.props.topics.findIndex(k=> Number(topicId) ? Number(k.id) === Number(topicId) : k.uuid === topicId);
        if(n !== -1)
            return this.props.topics[n]
        else return null
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            if(typeof(k[k.length-1]) !== "object"){
                
                let id = [...k][0];
                let status = k[6];
                k[0] = i+1
                let topic = this.getTopic(this.props.telegramItems.data[i].topicId)
                k[1] = topic ? topic.name : ''
                k[2] = (this.props.telegramItems.data[i].subtopicId && (topic && topic.subtopics)) ? this.getSubtopic(this.props.telegramItems.data[i].subtopicId, topic.subtopics).name : ''
                let ctrl = [];
                ctrl = this.props.controlButtons;

                k[5] = k[5] && k[5] !== undefined ? (this.getFilterName(k[5]) ? this.getFilterName(k[5]).name : '') : ''
                k.push(this.getControlButtonItems(id, status, ctrl, i))
            }
            return k;
        })
        
    }

    onTableItemsClick = (type, key, index) => {
        let selectedTopic = null
        this.props.setSelectedTelegramItem(null)
        
        //this.props.setT
        selectedTopic = {}
        switch(type){
            case 'create':{
                selectedTopic.showType = type;
                this.showRightMenu(true, "CREATE_SUBSCRIPTION_NOTIFICATION", selectedTopic);
                this.props.setSubscriptionPolicy(null, null)
                this.props.setSelectedTelegramTopic(null, null)
                this.props.setSubscriptionEmails([])
                break;
            }
            case 'show':
            case 'edit':{
                
                selectedTopic = this.props.telegramItems.data[index]
                selectedTopic.showType = type;
                selectedTopic.filter = (this.props.telegramItems.data[index] && this.props.telegramItems.data[index].filterId) ? this.getFilterName(this.props.telegramItems.data[index].filterId) : null
                this.showRightMenu(true, type === 'edit' ? "NOTIFICATION_EDIT" : "NOTIFICATION_VIEW", selectedTopic)
                let t = this.getTopic(this.props.telegramItems.data[index].topicId)
                
                if(t && t.id && t.uuid){
                    selectedTopic.findedTopic = t
                    this.props.setSelectedTelegramTopic(t.id, t.uuid)
                    this.props.setSelectedTelegramItem(selectedTopic)
                }
                
                break;
            }
            case 'copy':{
                if(!this.props.alertPolicy){
                    this.props.getSubscriptionAlertPolicies(this.props.telegramItems.data[index].id)
                    setTimeout(()=>{this.onTableItemsClick(type, key, index)}, 1000) 
                    return
                }
                selectedTopic.topicId = this.props.telegramItems.data[index].topicId
                selectedTopic.subtopicId = this.props.telegramItems.data[index].subtopicId
                selectedTopic.filterId = this.props.telegramItems.data[index].filterId
                selectedTopic.daysLimit = this.props.telegramItems.data[index].daysLimit
                this.props.copySubscription(this.props.telegramItems.data[index].id, selectedTopic.topicId, selectedTopic.subtopicId, selectedTopic.filterId, selectedTopic.daysLimit, this.props.alertPolicy)
                break;
            }
            case 'delete':
                this.props.deleteSubscription(key);
                this.props.setShowRightMenu(false);
                this.props.setRightMenuComponent(null);
                break;
            case 'ACTIVE':
                this.props.resumePauseSubscription(key, 'pause');
                break;
            case 'PAUSED':
                this.props.resumePauseSubscription(key, 'resume');
                break;

        }

    }

    componentDidMount(){
        this.props.getSubscriptions();
        this.props.getAllFilters();
        //this.showRightMenu(true);
    }

    componentDidUpdate(){
        if(this.props.isDataUpdated){
            this.props.getMyTopics();
        }
        if(this.props.selectedTelegramItem && (this.props.selectedTelegramItem.id!== this.state.selectedTelegramItem)){
            //if(this.props.selectedTelegramItem.topicId)this.props.getSubtopics(this.props.selectedTelegramItem.topicId)
            this.setState({
                selectedTelegramItem: this.props.selectedTelegramItem ? this.props.selectedTelegramItem.id : null
            })
        }
    }

    componentWillUnmount(){
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setSubtopicItem([])
        this.props.setSubtopicsUpdated(true)
        //this.props.setRemoveLeftMenuComponent()
        this.props.setRemoveRightMenuComponent();
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    render(){
        return <Telegram {...this.props} tableHeader={this.getTableHeader(this.props.tableHeader)} hiddenColumns={this.state.hiddenColumns} data={(this.props.telegramItems && this.props.telegramItems.table && this.props.telegramItems.table.length) && this.setTableValues(this.props.telegramItems.table)} onTableItemsClick={this.onTableItemsClick}/>
    }
}

export default TelegramController;