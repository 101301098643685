import React from "react";
import TableItem from "../../items/table/table"//"../../../../items/table/table";
import s from "./favourite-list.module.scss";

const FavouriteList = (props) => {

    const getHiddenColumns = (deviceType) => {
        switch(deviceType){
            case 'mobile':
                return [0,1,3,4,5,6]
            case 'tablet':
                return [0,1,3]
            default:
                return [1]
        }
    }

    return (
        <div className={s.FavouriteList}>
            {<TableItem 
                data={[props.tableHeaderName, props.data]}  
                getValue={props.getValue}
                //hiddenColumns={[1]}
                titleText={props.getValue('FAVORITE_AUTHORS_AND_SOURCES')}
                height={230}
                hidden={getHiddenColumns(props.deviceType)}
                /> 
            }
        </div>
    )
}

export default FavouriteList;