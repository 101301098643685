import React from "react";
import TableItem from "items/table/table";
import s from "./tags.module.scss"

const Tags = (props) => {
    return (
        <div className={s.Tags}>
            <TableItem 
                data={[props.tableHeader, props.data, props.styles]}
                //addItemButton={addItemButton}
                hiddenColumns={props.hiddenColumns}
                getValue={props.getValue} 
                titleText={props.getValue('TAGS_LIST')}
                height={645}
                hidden={props.deviceType === 'mobile' ? [0,2,3,4,5,6] : []}
                />
        </div>
    )
}

export default Tags