import React, {useState, useEffect} from "react";
import { StandartButton } from "../../commons/form-controls";
import { InputText } from "../../commons/input-controls";
import DropdownController from "../../items/dropdown/dropdown-controller";
import s from "./modal-report.module.scss";
import { ReactComponent as DownlaodIcon } from '../../assets/icons/download.svg';
import { getFormatDate, getFormatedDate } from "service/format-date";
import EmailsList from "../../items/emails-list/emails-list";
import moment from "moment";

const ModalReport = (props) => {

    const getDateFormat = (date) => {
        return getFormatDate(date, "DD-MM-YYYY HH:mm:ss");//moment(date).format("DD-MM-YYYY HH:mm:ss")
    }

    const formatDate = (date) => {
        return getFormatedDate(date)
    }

    const [emails, setEmails] = useState([props.email]);
    const [selectedReportFormat, setSelectedReportFormat] = useState('pdf');
    const [selectedReportType, setSelectedReportType] = useState('С заголовками');
    const [startDate, setStartDate] = useState(getDateFormat(props.startDate));
    const [endDate, setEndDate] = useState(getDateFormat(props.endDate));
    const [reportFormatTypes, setReportFormatTypes] = useState(props.reportFormatTypes.map(k => {return {title: props.getValue(k.title), name: k.name, short_name: k.short_name}}))
    const [reportSended, setReportSended] = useState(props.reportSended);
    const [reportTypes, setReportTypes] = useState(props.reportTypes)
    const [topicTitle, setTopicTitle] = useState(props.selectedTopicsItems && props.selectedTopicsItems.map(k => k.name).join(','))
    const [subtopicTitle, setSubopicTitle] = useState(props.selectedSubtopicsItems && props.selectedSubtopicsItems.map(k => k.name).join(','))

    useEffect(() => {
        setReportSended(props.reportSended)
        setTopicTitle(props.selectedTopicsItems && props.selectedTopicsItems.map(k => k.name).join(','))
        setSubopicTitle(props.selectedSubtopicsItems && props.selectedSubtopicsItems.map(k => k.name).join(','))
        //setEmails([props.email])
    }, [props])

    const changeReportFormatType = (type, e) => {
        setSelectedReportFormat(e)
    }

    const changeReportType = (type, e) => {
        setSelectedReportType(e)
    }



    const setEmailsList = (emails) => {
        setEmails(emails)
    }

    const getFeedTypeByPageName = (pageName) => {
        switch(pageName){
            case 'BlackList':
                return 'blacklisted';
            case 'FavouritePublications':
                return 'favourite_manual';
            case 'Favourite':
                return 'favourite_auto'
            case 'RecycleBin':
                return 'deleted'
            default:
                return 'main'
        }
    }
    const sendReport = () => {
                    //let subtopics = props.selectedSubUUId ? {subtopicIds: [props.selectedSubUUId]} : []
                    let filters = {...props.selectedItems}
                    if(props.searchList && Object.keys(props.searchList).length !== 0){
                        filters.clauses = props.searchList
                    }
        if(emails){
            let e = emails.filter(e => e !== null)
            if(!props.selectedSubtopics || !props.selectedSubtopics.length){
                /*!props.pageName || props.pageName === 'Documents'
                    ? props.sendReport(selectedReportFormat, props.selectedUUId, e, formatDate(props.startDate), formatDate(props.endDate), props.grouping, filters, getFeedTypeByPageName(props.pageName), props.selectedPostItems)
                    :*/ props.sendMultipleReport(selectedReportFormat, props.selectedTopics, e, formatDate(props.startDate), formatDate(props.endDate), props.grouping, filters, getFeedTypeByPageName(props.pageName), props.selectedPostItems)
            }
            else{
                props.sendSubtopicReport(selectedReportFormat, props.selectedTopics, props.selectedSubtopics, e, formatDate(props.startDate), formatDate(props.endDate), props.grouping, filters)
            }
        }
    }

    const dropdown =  <DropdownController
        items={reportFormatTypes}
        name={"FORMAT"}
        callback={changeReportFormatType}
        selected={selectedReportFormat}
        icon={null}
        type="Menu"
        short_name={"short_names"}
        background={"var(--echoSecondary)"}
        width={195}
        style={{width: 195}}
    />

    const getSubtopicNameByUUID = (uuid) => {
        let f = props.subtopics && props.subtopics.filter( k => {
           return k.uuid === uuid
        })
        return (f && f[0]) ? f[0].name : null
    }

    const reportTypesDropdown =  <DropdownController
        items={reportTypes}
        name={"Type"}
        callback={changeReportType}
        selected={selectedReportType}
        icon={null}
        type="Menu"
        short_name={"short_names"}
    />

    const showListOfNotification = () => {
        props.modalShowToggle('NOTIFICATIONS', true, null, null, reportSended)
    }

    const goToNotificationsStatuses = <StandartButton title={props.getValue('LIST_OF_NOTIFICATIONS')} callback={showListOfNotification}/>

    const getTopicTitle = () => {
        if(!props.selectedSubtopics || !props.selectedSubtopics.length){
            if(props.selectedTopics && props.selectedTopics.length && props.selectedTopics.length > 1)
                return <span title={topicTitle}>{props.selectedTopics.length+' '+props.getValue('TOPICS')}</span>
            else return <span>{props.selectedTopicsItems[0].name}</span>
        }
        else{
            if(props.selectedSubtopics && props.selectedSubtopics.length && props.selectedSubtopics.length > 1)
                return <span>{props.selectedSubtopics.length+' '+props.getValue('TOPICS')}</span>
            else return <span>{props.selectedSubtopicsItems[0].name}</span>
        }

    } 

    return (
        <div className={s.ModalReport}>
            <div className={s.row}>
                {(subtopicTitle ? props.getValue('SUBTOPIC') : props.getValue('TOPIC_2')) + ': '}
                <span className={s.blueText}>{getTopicTitle()}</span>
            </div>
            <div className={s.row}>
                <span>{props.getValue('DATE_2')}: {startDate}</span>
            </div>
            <div className={s.row}>
                <span>{props.getValue('DATE_2')}: {endDate}</span>
            </div>
            <div className={s.row}>
                <span className={s.label}>{props.getValue('FORMAT')}</span>
                {dropdown}
            </div>
            {/* props.selectedPostItems ? <div className={s.row}>
                <span>{props.getValue('NUMBER_OF_SELECTED_POSTS')}: {props.selectedPostItems.length}</span>
    </div> : null */}
            <div className={s.row}>
                <EmailsList emails={emails} callback={setEmailsList}/>
            </div>

            <div className={s.row}>
                <StandartButton disabled={reportSended} title={props.getValue('DOWNLOAD')} callback={sendReport} icon={DownlaodIcon} fill={'white'}/>
            </div>
            <div className={s.row}>
                {reportSended 
                                ? (emails.length 
                                                ? <span>{ props.getValue('REPORT_IN_PROGRESS_AND_WILL_BE_SENDED_TO_EMAIL')}</span> 
                                                : <span>{ props.getValue('REPORT_IN_PROGRESS_AND_WILL_AVAILABLE_IN_NOTIFICATIONS') }</span>) 
                                : <span>{ props.getValue('FORMAT_REPORT_AND_WILL_BE_SENDED_TO_EMAIL') }</span>}
                {reportSended && goToNotificationsStatuses}
            </div>
        </div>
    )
}

export default ModalReport;