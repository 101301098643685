import React from "react";
import ExtendedAnalytic from "./extended-analytic";
import SettingsMenuContainer from "../settings-page/menu/settings-menu-container";
import PeriodCalendarContainer from "items/period-calendar/period-calendar-container";
import TreeContainer from "items/tree/tree-container";
import FiltersContainer from "items/filters/filters-container";
import Preload from "items/preload/preload";
import MyFiltersContainer from "items/myFilters/my-filters-container";
import ExtendedAnalyticPages from "./extended-analytic-pages/extended-analytic-pages";
import TopicsComparisonPages from "./topics-comparison-pages/topics-comparison-pages";
import TopicsSelectMenuContainer from "items/topics-select-menu/topics-select-menu-container";
import moment from 'moment';

import SearchTextContainer from "items/search-text/search-text-container";
import { getFormatedDate } from "service/format-date";

class ExtendedAnalyticController extends React.Component{
    constructor(props){
        
        super(props);

        this.selected = this.props.selected;
        this.selectedTopics = null;
        this.analyticContainer = null;
        this.selectedFilterItems = [];
        this.requestSended = false;
        this.start = true;
        this.searchList = {}
        this.selectedTopicUUId = (this.props.topicData && this.props.topicData.length) ? this.props.topicData[0].uuid : this.props.selectedUUId;
        this.state = {
            data: false,
            requestSended: false,
            selectedFilterItems: [],
            startDate: props.startDate, 
            endDate: props.endDate,
            selectedSubUUId: props.selectedSubUUId,
            selectedUUId: props.selectedUUId,
            grouping: "DAILY",
            selectedLanguage: props.selectedLanguage,
            pageA: null,
            firstStart: false,
            pageType: this.props.type,
            selectedTopicsItems: JSON.stringify(this.props.selectedTopicsItems),
            //itemType: this.props.mediaTypes.itemType,
            searchList: this.props.searchList,
            first: this.props.first,
            second: this.props.second,
            menuId: this.props.selected
        }
        
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date);
    }

    getFilters = () => {
       
        return this.state.firstStart ? {} : {...this.selectedFilterItems/*, ...subtopics*/}
    }

    getPageMenuByPageType = () => {

    }

    setRequestSended = () => {
        this.setState({
            requestSended: false
        })
        
        //this.props.setComparisonUpdated(false)
        setTimeout(() => {
            if(this.props.started) this.props.setStarted(false)
            if(this.props.isFetchingAnalytic) this.props.setAnalyticIsFetching(false)
        }, 700)
        //this.props.setAnalyticIsFetching(false)
    }

    getComparisonData = (type) => {
        switch(type){
            case 'MEDIA_TYPES':
                return this.props.mediaTypes;
            case 'SENTIMENTS':
                return this.props.mediaTypes;
            case 'SPECIAL_MARKS':
                return this.props.mediaTypes;
            default:
                return this.props.mediaTypes
        }
    }

    getTopicComparisonData = (isNotMatched = false) => {
        let filters = this.getFilters();
        if(this.searchList)
            filters.clauses = this.searchList
        let topics = this.props.selectedTopicsItems && Object.entries(this.props.selectedTopicsItems).length ? Object.keys(this.props.selectedTopicsItems) : []
        let menuType = this.props.settingsMenuItems[this.props.selected] && this.props.settingsMenuItems[this.props.selected].title

        if(topics && topics.length && !this.props.comparisonUpdated){
            this.props.setSelectedTopics(topics, [])
            this.props.getTopicComparisonData(menuType, this.props.selectedType, topics, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, filters, isNotMatched)
        }
        else{
            return <div>no data</div>
            
        }
    }


    getPageData = (menuId) => {
        if(this.selected !== this.props.selected) this.selected = this.props.selected;
        let filters = this.getFilters(); // this.state.firstStart ? {} : {...this.selectedFilterItems, ...subtopics}
        if(this.searchList)
            filters.clauses = this.searchList
        this.props.setIsFilterChanged(false);

        if(!this.props.isFetching && !this.props.started){
            this.props.setPageName(this.props.type)
            if(this.props.type === 'ExtendedAnalytic'){
                //if(this.props.first && this.props.first.topicIDs.length){
                    //setTimeout(() => {

                        this.props.getDataByType(this.props.menuItems[menuId].title, this.state.firstStart ? this.props.topicData[0].uuid : this.props.selectedUUId, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, filters, this.props.selectedSubUUId, this.props.tags);
                    //}, 500)
                    //}
                    
                
                
          
                //type3 && this.props.getDataByType(type3, this.state.firstStart ? this.props.topicData[0].uuid : this.props.selectedUUId, this.getFormatedDate(this.props.startDate), this.getFormatedDate(this.props.endDate), this.props.grouping, filters);
            }
            else{
                if(this.props.selectedTopicsItems && Object.keys(this.props.selectedTopicsItems).length && !this.props.isFetchingAnalytic){
                    this.getTopicComparisonData();
                }
                else{

                }
            }
            this.setState({
                data: true,
                requestSended: this.props.requestSended,
                startDate: this.props.startDate,
                endDate: this.props.endDate,
                selectedSubUUId: this.props.selectedSubUUId,
                selectedUUId: this.props.selectedUUId,
                grouping: this.props.grouping,
                selectedLanguage: this.props.selectedLanguage,
                firstStart: false,
                pageType: this.props.type,
                selectedTopicsItems: JSON.stringify(this.props.selectedTopicsItems),
                isFetchingAnalytic: this.props.isFetchingAnalytic,
                searchList: this.props.searchList,
                first: this.props.first,
                second: this.props.second,
                menuId: this.props.selected
            })

        }
            if(this.props.type === 'ExtendedAnalytic'){
                setTimeout(() => {
                    this.setState({
                        pageA: ExtendedAnalyticPages(this.props, this.setRequestSended, this.state.selectedUUId)
                    })
                    this.props.setReturnBackPostCount(this.props.first.total)
                }, 500)

            }
            else if(this.props.type === 'Comparision' && this.props.comparisonUpdated){
                //setTimeout(() => {
                
                if(this.props.selectedTopicsItems && Object.keys(this.props.selectedTopicsItems).length){
                    let title = this.props.settingsMenuItems[this.props.selected] && this.props.settingsMenuItems[this.props.selected].title ? this.props.settingsMenuItems[this.props.selected].title  : ''
                    
                    this.setState({
                        pageA: TopicsComparisonPages(menuId, this.getComparisonData(title), this.props.getValue, this.props.topicData, this.setRequestSended, this.props.settingsMenuItems[this.props.selected].title, this.getTopicComparisonData ,this.props.selectedType, this.props.topicUUId, this.props.selectedTopicsItems, this.props.isChanged)
                    })
                    //this.props.setComparisonUpdated(false)
                    this.start = false;
                }
                else{
                    this.setState({
                        pageA: <div></div>
                    })
                    this.start = false;
                }
            //})
            }

        //}, 1000)
        
    }

    setMenuByPageType = () => {
        switch(this.props.type){
            case 'Comparision':
                this.props.setMenus(this.props.comparisonMenu);
                break;
            case 'ExtendedAnalytic':
                this.props.setMenus(this.props.menuItems);
        }
    }

    checkMenu = () => {
        if(this.props.type !== this.state.pageType){
            return false
        }
            switch(this.props.type){
                case 'Comparision':
                    return !this.props.comparisionMenu || !this.props.comparisionMenu.length ? false : true
                case 'ExtendedAnalytic':
                    return !this.props.menuItems || !this.props.menuItems.length ? false : true
            }
        

    }

    checkDatePeriodToOneDay = (startDate, endDate) => {
        let s = moment(startDate, "YYYY-MM-DDTHH:mm:ssZ")
        let e = moment(endDate, "YYYY-MM-DDTHH:mm:ssZ")
        if(e.add(1, 'days').isAfter(s)){
            let start = moment().subtract(6, 'days')
            start.startOf('day')
            this.props.setStartDate(new Date(start.format('YYYY-MM-DDTHH:mm:ssZ'))) 
            this.props.setEndDate(new Date(moment().endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')))
            this.props.setGrouping("DAILY")
            this.setState({
                grouping: "DAILY"
            })
        }
    }

    componentDidMount(){
        this.props.setMenus(this.props.menuItems)
        this.setMenuByPageType(this.props.type)
        this.props.setShowLeftMenu(true);
        this.props.setShowRightMenu(true);
        this.props.getMyTopics();
        this.props.setShowPanel(true)
        this.props.setLeftMenuComponent(<SettingsMenuContainer items={this.props.menuItems}/>, 'settingsMenu');
        this.props.setRightMenuComponent(<MyFiltersContainer />, 'myFilters', true)

        this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'grouping'}/>, 'periodCalendar');
        if(!this.props.isReturnedBack){
            this.checkDatePeriodToOneDay(this.props.startDate, this.props.endDate);
        }
        else{
            this.props.setIsReturnedBack(false)
        }

        //if(this.props.type === 'ExtendedAnalytic')
            //if(this.props.selectedUUId) this.getPageData(this.props.selected)
        //this.props.setRightMenuComponent(<CalendarContainer footerButtons={'grouping'}/>, 'calendar');
        
        //this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'grouping'}/>, 'periodCalendar');
        //this.props.setRightMenuComponent(<CalendarContainer footerButtons={'grouping'}/>, 'calendar');
        
        //this.props.setRightMenuComponent(<PeriodCalendarContainer footerButtons={'grouping'}/>, 'periodCalendar');
        this.props.setRightMenuComponent(<SearchTextContainer />, 'searchText')
        //if(this.props.selectedUUId) this.getPageData(this.props.selected)    
        this.setState({
            selectedUUId: this.props.selectedUUId
        })
    }

    getFormatedDate = (date) => {
        return getFormatedDate(date)
    }

    getFiltersList = (startDate, endDate, topics, fields, subtopics, filters) => {
        let type = this.props.selectedType
        
        let sTopics = {}
        if(this.props.type === 'Comparision' && this.props.selectedType === 'subtopic'){
            sTopics = [{subtopicIds: Object.keys(this.props.selectedTopicsItems).map((k,i) => {return k})}]
            topics = [this.props.topicUUId]
        }
        else{
            sTopics = subtopics
        }
        //let filters = {...this.props.selectedItems}

        this.props.getTopicsFilters(this.getFormatedDate(startDate), this.getFormatedDate(endDate), topics, fields, filters, subtopics);  
    }

    isGraphUpdated = () => {
        
        if(this.props.type === 'Comparision' && this.props.mediaTypes && this.props.mediaTypes.itemType !== this.state.itemType){
            this.setState({
                itemType: this.props.mediaTypes.itemType
            })
            return true
        }
        else{
            return false
        }
    }

    checkChanges = () => {
        if(
            !this.state.data && !this.props.isFetching
            || this.selected !== this.props.selected 
            || this.props.isChanged 
            || this.state.startDate.toString() !== this.props.startDate.toString() 
            || this.state.endDate.toString() !== this.props.endDate.toString()
            || this.props.selectedSubUUId !== this.state.selectedSubUUId 
            || this.state.selectedUUId && this.props.selectedUUId !== this.state.selectedUUId 
            || this.props.grouping !== this.state.grouping 
            || this.state.selectedLanguage !== this.props.selectedLanguage
            || this.props.type !== this.state.pageType
            || JSON.stringify(this.props.selectedTopicsItems) !== this.state.selectedTopicsItems
            || this.state.firstStart
            || this.isGraphUpdated()
            //|| this.isLengthUnmatched()
            //|| this.props.searchList !== this.state.searchList
            || this.props.comparisonUpdated
            || this.props.first.pageType && (this.props.first.pageType !== this.state.first.pageType)
            || this.state.second.pageType && this.props.second.pageType !== this.state.second.pageType
            || this.state.first.total && this.state.first.total !== this.props.first.total
            //|| this.state.selected !== this.props.menuId
            //|| (this.props.first.topicIDs.length && !this.props.isFetching) && (this.props.first.data && this.props.first.data.length !== this.state.first.data.length) && this.props.first.topicIDs !== this.state.first.topicIDs
            || this.props.started
        ){
            return true
        }
        else return false
    }

    componentDidUpdate(){
        this.selectedFilterItems = this.props.selectedItems;
        if(this.searchList !== this.props.searchList){
            this.searchList = this.props.searchList
        }
        if(this.props.type !== this.state.pageType){
            this.start = true;
            this.props.getMyTopics();
            //this.props.clearSearchList();
            this.setMenuByPageType();
            this.setState({
                searchList: null
            })

            //this.props.setTreeSelectedItems(0)
            //this.props.setMenus([])
        }
        if(this.props.selectedUUId && (this.props.selectedUUId !== this.state.selectedUUId)){
            this.setState({
                selectedUUId: this.props.selectedUUId
            })
        }
        if(this.props.first.pageType && (this.props.first.pageType !== this.state.first.pageType)){
            this.setState({
                first: this.props.first,
            })
        }
        if((this.props.second.pageType || this.state.second.pageType) && (this.props.second.pageType !== this.state.second.pageType)){
            this.setState({
                second: this.props.second,
            })
        }

        if(this.props.topicData && this.props.topicData.length){
            this.props.setThemes(this.props.topicData);
            if(this.props.selectedId && this.selectedTopics !== this.props.selectedTopic){
                //this.props.clearSearchList();
                this.searchList = [];
                //this.searchList = true;

                this.props.getSubtopics(this.props.selectedId);
                this.selectedTopics = this.props.selectedTopic;
                
                
            }
            if(this.props.type === 'Comparision'){
                if(this.start){
                    let selectedTopics = {}
                    this.props.topicData.forEach(k => { selectedTopics[k.uuid] = true})
                    this.props.setSelectedTopicsItems(selectedTopics, 'topic')
                    this.start = false
                }
            }
            let leftMenu = this.props.type === 'Comparision' ? 'topics' : 'tree';

            if(this.props.leftMenuComponent.findIndex( c => c.componentName === leftMenu) === -1 || this.props.isSubtopicsUpdated){
                this.props.setSubtopicsUpdated(false)
                this.props.setRemoveLeftMenuComponent()
                    this.props.type === 'Comparision' 
                                        ? this.props.setLeftMenuComponent(<TopicsSelectMenuContainer topics={this.props.topicData} />, 'topics', true)
                                        : this.props.setLeftMenuComponent(<TreeContainer items={this.props.topicData} subItems={this.props.subtopics}/>, 'tree', true)
                    this.props.setLeftMenuComponent(<SettingsMenuContainer items={this.props.menuItems} />, 'settingsMenu');
            }



            if(!this.props.component || this.selected !== this.props.selected){
                if(this.props.rightMenuComponent.findIndex( c => c.componentName === 'filters') === -1){
                    
                    this.props.setRightMenuComponent(<FiltersContainer topics={this.props.topicData[this.props.selectedTopic]} getFiltersList={this.getFiltersList}/>, 'filters')
                }
            }
            if(
                this.checkChanges()

            ){
                if(this.props.type === 'ExtendedAnalytic'){
                    if(this.props.selectedUUId) this.getPageData(this.props.selected)
                }
                else if(this.props.selectedTopicsItems){
                    this.getPageData(this.props.selected)
                }
            }
        }
        else
            if(!this.props.topicData) this.props.getMyTopics(this.props.role)

    }

    shouldComponentUpdate(nextProps, nextState){
        return true
        
    }

    componentWillUnmount(){
        this.props.setRemoveLeftMenuComponent();
        this.props.setRemoveRightMenuComponent();
        this.props.setShowLeftMenu(false);
        this.props.setShowRightMenu(false);
        this.props.setShowPanel(false);
        this.props.setClearData();
        //this.props.setThemeTableData([]);
        //this.props.setSubtopics([]);
        this.props.setReturnBackPostCount(0);

        //this.props.clearSearchList();
    }

    render(){
            return (this.state.pageA) 
                                    ? /*(!this.props.isFetching && !this.requestSended) 
                                        ? */<ExtendedAnalytic analyticPage={this.state.pageA} selectedTopicsItems={this.props.selectedTopicsItems} selectedFilterItems={this.selectedFilterItems} {...this.props}/> 
                                        //: <Preload />
                                    : <div></div>
    }
}

export default ExtendedAnalyticController;
