import React, {useState, useEffect} from "react";
import ApexChartContainer from "apex-charts/apex-chart-container";
import s from "./short-analytic-chart-item.module.scss"

const ShortAnalyticMobileChartItem = (props) => {

    const [dataCols, setDataCols] = useState(props.data && (props.data.numFoundNeutralToday || props.data.numFoundNegativeToday || props.data.numFoundPositiveToday) ? [props.data.numFoundNeutralToday, props.data.numFoundPositiveToday,props.data.numFoundNegativeToday] : []);
    
    
    useEffect(() => {
        setDataCols(props.data && (props.data.numFoundNeutralToday || props.data.numFoundNegativeToday || props.data.numFoundPositiveToday) ? [props.data.numFoundNeutralToday,props.data.numFoundPositiveToday,props.data.numFoundNegativeToday] : []);
    }, [props.data])

    let labels = [props.getValue('NEUTRAL'), props.getValue('POSITIVE'), props.getValue('NEGATIVE')];
    let list = ['NEUTRAL', 'POSITIVE','NEGATIVE'];
    let pieChartColors = ["var(--echoSecondary)", "var(--green)", "var(--echoRed)"]
    let data = {labels: labels, values: dataCols, list: list}
    let arrowSize = window.screen.availWidth < 1800 ? 18 : 28;

    return (
        <div className={s.AnalyticItemMobile}>
            <div className={s.topChartBlock}>
                <div className={s.ChartBlockTop}>
                    <div><span className={s.chartItemCaption}>{props.getValue('WEEK')}</span></div>
                    <div className={s.WeekCount}>
                        {props.getArrowByValues(props.data.numFoundPositivePrevWeek, props.data.numFoundWeek, 22)}
                        <span>{props.getFormattedCount(props.data.numFoundWeek)}</span>
                    </div>
                    
                </div>
            </div>
            <div className={s.Chart}>
                <div className={s.PieChartBlock}>
                {dataCols 
                    && <ApexChartContainer
                        noData={props.getValue('NO_DATA')} 
                        type={'donut'} 
                        data={data} 
                        getValue={props.getValue} 
                        colors={pieChartColors}
                        width={190}
                        yesterday={props.data.numFoundYesterday > 999 ? props.getFormattedCount(props.data.numFoundYesterday) : props.data.numFoundYesterday}/>
            }
                </div>

            </div>

                <div className={s.topChartBlockTop}>
                    <div className={s.KeyValues+" "+s.green}>
                        {props.getArrowByValues(props.data.numFoundPositivePrevWeek, props.data.numFoundPositiveWeek, arrowSize, 'var(--echoGreen)')}
                        <span>{props.getFormattedCount(props.data.numFoundPositiveWeek)}</span>
                    </div>
                    <div className={s.KeyValues+" "+s.blue}>
                        {props.getArrowByValues(props.data.numFoundNeutralPrevWeek, props.data.numFoundNeutralWeek, arrowSize, 'var(--echoBlue)')}
                        <span>{props.getFormattedCount(props.data.numFoundNeutralWeek)}</span>
                    </div>
                    <div className={s.KeyValues+" "+s.red}>
                        {props.getArrowByValues(props.data.numFoundNegativePrevWeek, props.data.numFoundNegativeWeek, arrowSize, 'var(--echoRed)')}
                        <span>{props.getFormattedCount(props.data.numFoundNegativeWeek)}</span>
                    </div>
                </div>
        </div>
    )
}

export default ShortAnalyticMobileChartItem