import React from "react";
import CompanyTopicsTable from "./company-topics-table";
import ControlButtons from "items/control-buttons/control-buttons";
import CompanyTopicItem  from "./company-topic-item/company-topic-item";
import List from "pages/settings-page/list/list";
import CompanyTopicAddItemContainer from "./company-topic-add-item/company-topic-add-item-container";
import AdminAddItem from "pages/admin/admin-add-item/admin-add-item";

class CompanyTopicsTablesController extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            hiddenColumns: [4,8],
            topicAccounts: this.props.topicAccounts,
            selectedTopics: []
        }
    }

    getTableHeader = (header) => {
        return header.map(k=> this.props.getValue(k))
    }

    closeRightMenu = () => {        
        this.props.setShowRightMenu(false);
        this.props.setRightMenuComponent(null);
        this.props.setRemoveRightMenuComponent();
    }

    getAccountById = (accountId) => {
        return this.props.accounts && this.props.accounts.accounts ? this.props.accounts.accounts.find(k => k.id === accountId) : null
    }

    addAccountToTopic = (item) => {
        this.props.attachTopicToAccount(this.props.companyId, item, this.props.topicId, this.props.isSuperadmin, false);

        this.closeRightMenu()
    }

    addMarkerToTopic = (item) => {
        let account = this.getAccountById(item);

        this.props.makeTopicVisibleToMarker(item, this.props.topicId, this.props.companyId, false)
        this.closeRightMenu()
    }

    removeAccountFromTopic = (item) => {
        let account = this.getAccountById(this.props.accountId);
        if(account && account.role && account.role === 'ROLE_MARKER')
            this.props.detachMarkerTopic(this.props.accountId, item) 
        else
            this.props.detachTopicFromAccount(this.props.companyId, this.props.accountId, item, this.props.isSuperadmin, false)
    }

    showRightMenu = (show, topicId, index) => {
        this.closeRightMenu()
        if(show){
            let ftopic = this.props.companyTopics.topics[index];
            this.props.setShowRightMenu(true)
            switch(show){
                case 'addItem':
                    //this.props.setRightMenuComponent(<AdminAddItem markers={this.props.markers} items={this.props.accounts.accounts} type={'accounts'} callback={this.addAccountToTopic} getValue={this.props.getValue}/>, 'accountsItems', null, this.props.getValue('ATTACH_TOPIC_TO_ACCOUNT'), true);
                    this.props.setRightMenuComponent(<CompanyTopicAddItemContainer topicId={topicId}/>, 'accountsItems', null, this.props.getValue('ATTACH_TOPIC_TO_ACCOUNT'), true);
                    //if(this.props.isSuperadmin) this.props.setRightMenuComponent(<AdminAddItem items={this.props.markers} type={'markers'} callback={this.addMarkerToTopic} getValue={this.props.getValue}/>, 'markerItems', null, this.props.getValue('ATTACH_TOPIC_TO_ACCOUNT'), true, this.props.getValue('ATTACH_TOPIC_TO_MARKER'));
                    break;
                case 'edit':
                    this.props.setRightMenuComponent(<CompanyTopicItem topic={ftopic} getValue={this.props.getValue} readOnly={false}/>, 'company', null, this.props.getValue('TOPIC'), true);
                    break;
                case 'show':
                    this.props.setRightMenuComponent(<CompanyTopicItem topic={ftopic} getValue={this.props.getValue} readOnly={true}/>, 'company', null, this.props.getValue('TOPIC'), true);
                    break;
            }
            /*case 'add':
                this.props.setRightMenuComponent(<AdminAddItem type={'accounts'} callback={this.addItem} getValue={this.props.getValue}/>, 'accountsItems', null, this.props.getValue(title), true);*/
            //let id = title;
            
            
        }
    }

    onTableItemsClick = (type, key, index) => {
        this.setState({
            title: type
        })
        this.title = type;
        let selectedTopic = null
        /*

        this.props.pauseTopic(key);
                break;
            case 'PAUSED':
                this.props.startTopic(key);
        */
        switch(type){
            case 'show':
                this.showRightMenu('show', key, index)
                break;
            case 'create':
                break;
            case 'edit':
                this.showRightMenu('edit', key, index)
                break;
            case 'delete':
                if (this.props.isAccountTopics || this.props.isTopicAccounts) {
                    this.removeAccountFromTopic(key)
                } else {
                    this.props.deleteCompanyTopic(key, this.props.companyId)
                }
                
                break;
            case 'remove':
                this.removeAccountFromTopic(key)
                break;
            case 'copy':
                break;
            case 'ACTIVE':
                this.props.pauseTopic(key, this.props.companyId, this.props.isSuperadmin);
                break;
            case 'PAUSED':
                this.props.startTopic(key, this.props.companyId, this.props.isSuperadmin);
                break;
            case 'add':
                this.showRightMenu('addItem', key, index)    
                break;
        }
    }

    getControlButtonItems = (id, status, items, index) => {
        let c = [];
        c = [...items];
        !this.props.isAccountTopics && c.unshift(status)
        return <ControlButtons keyId={id} callback={this.onTableItemsClick} status={status} items={c} index={index}/>
    }

    setTableValues = (tableValues) => {
        return tableValues.map((k, i)=>{
            let item = [];
            if(typeof(k[k.length-1]) !== "object"){
                let id = k[0];
                k[0] = i+1;
                let status = k[4];
                
                let ctrl = !this.props.isAccountTopics ? this.props.topicsTableControlButtons : this.props.accountsTopicTablerControlButton;
                k.push(this.getControlButtonItems(id, status, ctrl, i));
            }
            return k
        })
    }

    render(){
        const data = this.props.isAccountTopics ? (this.props.accountTopics.data && this.props.accountTopics.data.length) && this.setTableValues(this.props.accountTopics.data)
                                              : (this.props.companyTopics.data && this.props.companyTopics.data.length) && this.setTableValues(this.props.companyTopics.data)
        return (
            <div>
                <CompanyTopicsTable 
                                data={data}
                                hiddenColumns={this.state.hiddenColumns} 
                                tableHeader={this.getTableHeader(this.props.tableHeader)}
                                companyId={this.props.companyId}
                                companyTopics={this.props.companyTopics}
                                setTopicId={this.props.setTopicId}
                                deviceType={this.props.deviceType}
                />
            </div>
        )
    }
}

export default CompanyTopicsTablesController
